<template>
  <div class="warning-container">
    <div>
        <scg-form-btn
          @add="handleAdd"
          @search="handleSearch"
          @reset="handleReset('form')"
          :showFormBasicBtn="false">
          <el-button type="normal" class="func-btn" icon="el-icon-upload2" @click="handleImport">导出统计数据</el-button>
          <el-button type="normal" class="func-btn" icon="el-icon-download" @click="handleDownload">下载明细</el-button>
          <div slot="right">
            <el-checkbox v-model="checkMe" @change="handleChecked">只看本单位</el-checkbox>
          </div>
        </scg-form-btn>
    </div>
    <div class="result">
      <el-table fixed
              ref="filterTable"
              :data="tableData"
              border
              >
        <el-table-column type='index'
                          width="55"
                          label="序号"
                           />
        <el-table-column label="项目名称" prop="projectName" align="center" />
        <el-table-column label="所属公司" prop="companyAbbreviation" align="center" />
        <el-table-column label="超时预警" prop="overtimeWarningNum" align="center" />
        <el-table-column label="缺勤预警 " prop="absenceWarningNum" align="center" />
        <el-table-column label="证书预警" prop="certificationWarningNum" align="center" />
        <el-table-column label="超龄预警" prop="overageWarningNum" align="center" />
        <el-table-column label="危险工种预警" prop="dangerWorkTypeWarningNum" align="center" />
      </el-table>
      <scg-pagination :pagination="pagination" @currentPageChange="getCurrentPage" @handleSizeChange="getCurrentPageSize"></scg-pagination>
    </div>
    <scg-project-dialog
      :showDialog="showProjectDialog"
      @close="hiddenDialog" />
  </div>
</template>
<script>
import ScgPagination from '@/components/Scg-pagination'
import ScgFormBtn from '@/components/Scg-form-btn'
import ScgProjectDialog from '@/components/Scg-project-dialog'
export default {
  name: 'Warning',
  components: {
    ScgPagination,
    ScgFormBtn,
    ScgProjectDialog
  },
  data(){
    return {
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      form: {
        corpName:'', // 企业名称
        linkman:'', // 联系人姓名
        linkTel:'', // 联系电话
        province:'', // 所在省份
        city:'', // 所在城市
      },
      rules: {},
      genderList: [{
        label:'男',
        value:'1'
      },{
        label:'女',
        value:'2'
      }],
      workTypeList:[],
      teamList:[],
      showProjectDialog: false,
      checkMe: false,
    }
  },
  methods:{
    /**
     * 上移
     */
    moveUp(){
      console.log('setTop~')
    },
    /**
     * 下移
     */
    moveDown(){
      console.log('setTop~')
    },
    /**
     * 置顶
     */
    setTop(){
      console.log('setTop~')
    },
    handleNodeClick(){
      console.log('handleNodeClick~')
    },
    /**
     * 导出工人信息
     */
    handleExport(){
      console.log('handleExport~')
    },

    /**
     * 导入工人信息
     */
    handleImport(){
      console.log('handleImport~')
    },

    hiddenDialog(){
      this.showProjectDialog = false
    },
    /**
     * 选择项目
     */
    selectProject(){
      console.log('selectProject')
      this.showProjectDialog = true
    },

    /**
     * 新增
     */
    handleAdd(){
      console.log('handleAdd~')
    },

    /**
     * 重置
     */
    handleReset(formName){
      this.$refs[formName].resetFields()
      this.pagination.currentPage = 1
      this.getDataList()
    },

    /**
     * 查询
     */
    handleSearch(){
      console.log(this.form)
      this.pagination.currentPage = 1
      this.getDataList()
    },

    /**
     * 每页显示条目个数切换
     * @param val
     */
    getCurrentPageSize(val){
      this.pagination.currentPage = 1
      this.pagination.pageSize = val
      this.getCurrentPage()
    },
    
    /**
     * 页码切换，触发重载数据
     * @param val
     */
    getCurrentPage(val) {
      this.pagination.currentPage = val
      this.initData()
    },

    /**
     * 获取列表信息
     */
    getDataList(){
      this.tableData=[{
        companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        overtimeWarningNum: 8,
        absenceWarningNum: 6,
        certificationWarningNum: 10,
        overageWarningNum: 7,
        dangerWorkTypeWarningNum: 12
      },{
        companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        overtimeWarningNum: 8,
        absenceWarningNum: 6,
        certificationWarningNum: 10,
        overageWarningNum: 7,
        dangerWorkTypeWarningNum: 12
      },{
        companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        overtimeWarningNum: 8,
        absenceWarningNum: 6,
        certificationWarningNum: 10,
        overageWarningNum: 7,
        dangerWorkTypeWarningNum: 12
      },{
        companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        overtimeWarningNum: 8,
        absenceWarningNum: 6,
        certificationWarningNum: 10,
        overageWarningNum: 7,
        dangerWorkTypeWarningNum: 12
      },{
        companyName:'劳务单位1',
        companyAbbreviation: '劳务单位1',
        createTime:'2020-12-25',
        projectName:'项目1',
        overtimeWarningNum: 8,
        absenceWarningNum: 6,
        certificationWarningNum: 10,
        overageWarningNum: 7,
        dangerWorkTypeWarningNum: 12
      }]
    },

    /**
     * 初始化信息
     */
    initData(){
      this.getDataList()
    }
  },
  mounted(){
    this.initData()
  }
}
</script>
<style lang="stylus" scoped>
.warning-container
  background-color #fff
  margin 24px
  padding 10px
  
  .result
    border-top solid 1px #F0F2F5
    .list
      width 277px
      margin-top 37px
      
    .table
      flex 1
      padding-top 24px
      padding-left 24px
      border-left solid 1px #F0F2F5
</style>
<style lang="stylus">
// .labor-container
  
</style>