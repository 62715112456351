<template>
  <el-dialog
    :visible.sync="visible"
    width="600px"
    title="危险工种预警添加"
    :before-close="handleClose"
    custom-class="danger-setting-dialog-container"
    >
    <div>
      <el-form :model="form"
        label-position="right"
        label-width="180px"
        :rules="rules"
        >
        <!-- <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8"> -->
            <el-form-item label="工种:"
                      prop="projectName"
                      >
              <el-input v-model="form.projectName"
                        placeholder="请输入项目名称"
                        >
              </el-input>
            </el-form-item>
          <!-- </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8"> -->
            <el-form-item label="工种规定年龄(周岁):"
                      prop="projectLeader"
                      >
              <el-input v-model="form.projectLeader"
                        placeholder="请输入项目经理"
                        >
              </el-input>
            </el-form-item>
          <!-- </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8"> -->
          
          <!-- </el-col>
        </el-row>
         -->
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="normal" @click="handleClose" style="margin-left:10px">取消</el-button>
      <el-button type="primary" @click="handleSearch" style="margin-left:10px">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "Danger-setting-dialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data(){
    return {
      tableData: [],
      visible: this.showDialog,
      form:{},
      rules:{},
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      radio: 0,
    }
  },
  methods: {
    getCurrentRow(index){
      this.radio = index
      console.log(this.radio)
    },
    /**
     * 每页显示条目个数切换
     * @param val
     */
    getCurrentPageSize(val){
        this.pagination.currentPage = 1
        this.pagination.pageSize = val
        this.getCurrentPage()
      },
    /**
     * 页码切换，触发重载数据
     * @param val
     */
    getCurrentPage(val) {
      this.pagination.currentPage = val
      this.getDataList()
    },
    getDataList(){
      this.tableData = [{
        projectId:'001',
        projectCode:'202101030001',
        projectName:'项目1',
        projectLeader:'经理1'
      },{
        projectId:'002',
        projectCode:'202101030002',
        projectName:'项目2',
        projectLeader:'经理2'
      },{
        projectId:'003',
        projectCode:'202101030003',
        projectName:'项目3',
        projectLeader:'经理3'
      },{
        projectId:'004',
        projectCode:'202101030004',
        projectName:'项目4',
        projectLeader:'经理4'
      },{
        projectId:'005',
        projectCode:'202101030005',
        projectName:'项目5',
        projectLeader:'经理5'
      }]
    },
    handleSearch(){
      console.log('查询~')
      this.pagination.currentPage = 1
      this.getDataList()
    },
    handleClose(){
      this.visible = false
      this.$emit('close')
    }
  },
  watch: {
    showDialog() {
      this.visible = this.showDialog
    },
    visible(val, oldVal) {
      if (!val) {
        this.handleClose()
      }else{
        this.getDataList()
      }
    }
  }
}
</script>
<style lang="stylus">

</style>