var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-top":"20px"}},[_c('el-button',{ref:"VideoChose",attrs:{"id":"VideoChose","size":"mini"}},[_vm._v("选择文件")]),_c('el-button',{ref:"VideoChose",attrs:{"type":"primary","size":"mini"},on:{"click":_vm.FileUplodeOn}},[_vm._v("开始上传")]),_c('el-card',{staticStyle:{"margin-top":"20px"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.fileList}},[_c('el-table-column',{attrs:{"prop":"id","label":"文件id"}}),_c('el-table-column',{attrs:{"prop":"name","label":"文件名称"}}),_c('el-table-column',{attrs:{"prop":"type","label":"文件类型"}}),_c('el-table-column',{attrs:{"prop":"size","label":"文件大小"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.size)+"MB ")]}}])}),_c('el-table-column',{attrs:{"label":"进度"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-progress',{attrs:{"text-inside":true,"stroke-width":16,"percentage":row.percentage}})]}}])}),_c('el-table-column',{attrs:{"label":"取消上传"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","size":"mini","circle":""},on:{"click":function($event){return _vm.removeFile(row.id)}}})]}}])}),_c('el-table-column',{attrs:{"label":"上传状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{attrs:{"type":row.loadType==0?'info':row.loadType==1?'warning':row.loadType==2?'success':'danger',"underline":false}},[_vm._v(_vm._s(row.loadType==0?'等待上传':row.loadType==1?'正在上传':row.loadType==2?'上传成功':'上传失败'))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }